
import Card from "@/components/base/card/Card"
import Button from "@/components/base/Button"
import { routeToEditProfile, routeToBusiness } from "@/helpers/routing"
import { mdiChevronRight } from "@mdi/js"

export default {
    name: "ProfileCard",
    components: { Card, Button },
    data: () => ({
        mdiChevronRight,
    }),
    computed: {
        routeToEditProfile() {
            return routeToEditProfile(this.$auth.user)
        },
        routeToBusiness() {
            return routeToBusiness(this.$auth.user.business)
        },
    },
}
